.grid-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 320px auto;
  grid-template-rows: 1fr max-content;
  grid-template-areas:
    "sidebar contents"
    "footer contents";
  border-top: 6px solid var(--highlight);
}

.grid-sidebar {
  grid-area: sidebar;
}

.grid-contents {
  grid-area: contents;
}

.grid-footer {
  grid-area: footer;
}

.grid-sidebar, .grid-footer {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.grid-sidebar, .grid-contents, .grid-footer {
  padding: 15px;
}

.grid-sidebar, .grid-contents {
  padding-top: 20px;
}

.grid-contents {
  padding-bottom: 20px;
}
