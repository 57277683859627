/* Hide dropdown if the pill is not
   active or hovered */
.tree-node a.nav-link div.nav-link-tools {
  display: none;
}
.tree-node a.nav-link.active div.nav-link-tools {
  display: block;
}
/* Disable visibility animation */
.tree-node a.nav-link button.dropdown-toggle {
  height: 1.7rem;
  background-color: white;
  transition: visiblity 0s !important;
}
/* Fix vertical spacing for caret */
.tree-node a.nav-link div.dropdown > button.dropdown-toggle::after {
  margin: 0;
}
