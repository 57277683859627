.popover {
  font-family: 'Fira Sans', sans-serif;
}
.popover .popover-title {
  font-weight: bolder;
}
/* Improve the spacing within the popovers somewhat.
   This might turn out to be a bad idea in practice */
.popover .popover-header, .popover .popover-body {
  padding-top: 12px;
}

.hint-icon {
  color: var(--secondary);
  font-size: 0.9rem;
  cursor: pointer;
  transition: color 0.15s;
}
.hint-icon:hover {
  color: var(--grey);
}
