/* Line numbers */
.monaco-editor .line-numbers {
  font-size: 12px;
  top: 2px;
}

/* Active line highlight */
.monaco-editor .current-line {
  border: none !important;
}
.monaco-editor .view-overlays.focused .current-line {
  border: 2px solid #eee !important;
}

/* Bracket matching (disable when editor is not focussed) */
.monaco-editor .view-overlays:not(.focused) .bracket-match {
  border: none;
  background-color: transparent;
}

/* Overlay container
   (this rule is necessary because monaco adds the container
   to the bottom of the body element, which breaks the full-height
   grid layout in Firefox [not in Chrome, though]. This rule
   moves it to the top [left, outside the viewport])
*/
.monaco-aria-container {
  top: 0;
}

/* Add minimal height to editor container
   (this circumvents a bug in chrome, which reduces the editor to
   a single line if the editor height falls below a certain limit) */
.react-monaco-editor-container {
  min-height: 100px;
  /* On the off-chance that the editor is in a flexbox,
     use all available space */
  flex-grow: 1;
}
